import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Line,
  // Font,
} from "@react-pdf/renderer";
import moment from "moment";
import { FormattingNumber } from "../../../Helper/Helper";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingBottom: 105,
    paddingHorizontal: 35,
  },
  page: {
    paddingTop: 0,
    paddingBottom: 100,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  pagewrapper: {
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#777",
    paddingVertical: 10,
    width: "100%",
    height: "1.2in",
  },
  topSection: {
    padding: 15,
    // margin: 10,
    // padding: 10,
    // flex: "100%",
    // display: "flex",
    // flexDirection: 'space-between',
    // alignItems: 'center',

    width: "100%",
  },
  MainHeading: {
    fontSize: 24,
    fontWeight: "700",
    textAlign: "left",
    marginLeft: 0,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    width: 200,
    height: 50,
  },
  HeadingWrapper: {
    width: "50%",
    textAlign: "left",
  },
  InfoWrapper: {
    width: "60%",
    flex: 20,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    left: "3%",
    top: "12pt",
  },
  MainText: {
    fontSize: "9pt",
    marginVertical: 2,
  },
  SubHeading: {
    fontSize: "9pt",
    marginTop: 5,
    textAlign: "left",
    marginLeft: 0,
  },
  CustomerInfo: {
    display: "flex",
    flexDirection: "row",
    margin: "14pt",
    width: "100%",
    justifyContent: "space-between",
  },
  InfWrapper: {
    flex: 2,
  },
  ShippingAddress: {
    flex: 2,
    display: "flex",
    flexWrap: "wrap",
    marginHorizontal: 10,
  },
  BillingAddress: {
    flex: 2,
    display: "flex",
    flexWrap: "wrap",
  },
  logo: {
    width: "40%",
    height: 150,
    minHeight: 100,
    marginLeft: "0",
    marginRight: "auto",
    marginTop: -20,
  },
  grid: {
    borderWidth: 1,
    borderColor: "#777",
    borderStyle: "solid",
    width: "92%",
    marginTop: 10,
    marginHorizontal: "4%",
  },
  WordInText: {
    textAlign: "left",
    fontSize: 9,
    color: "#000000",
  },
  GridHeader: {
    backgroundColor: "#ccc",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#777",
  },
  GridProducts: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    height:"13.41px",
    borderBottom: '1 solid #999',
  },
  GridProductsItems: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",

  },
  productPart2: {
    flex: 4,
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: "#ccc",
    borderRightStyle: "solid",
    height: "100%",
    textAlign:'left',
  },
  InvoiceNumber: {
    fontSize: 10,
    position: "absolute",
    top: 50,
    left: 0,
  },

  gridHeading: {
    fontSize: 7,
    textAlign: "left",
    alignSelf: "left",
  },
  gridTextItems: {
    fontSize: 7,
    textAlign: "left",
    fontWeight: "Normal",
    alignSelf: "left",
    display:'flex',
    flexWrap: 'wrap',
    flexDirection:'row',
    alignContent: "flex-start",
    wordWrap: 'break-word',
  },
  gridText: {
    fontSize: 7,
    textAlign: "left",
    fontWeight: "Normal",
    alignSelf: "left",
    display:'flex',
    flexWrap: 'wrap',
    flexDirection:'row',
    alignContent: "flex-start",
    wordWrap: 'break-word',
  },
  gridText2: {
    fontSize: 7,
    textAlign: "left",
    fontWeight: "Normal",
    alignSelf: "left",
    display:'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    
    alignContent: "space-between",
    
  },
  gridText3: {
    fontSize: 7,
    textAlign: "center",
    fontWeight: "Normal",
    marginLeft: '1%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    
    
    
  },
  GDate: {
    flex: 2.4,
    padding: 2,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    height: "100%",
    paddingBottom: 0,
  },
  GCompany: {
    flex: 8,
    padding: 2,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    textAlign: "left",
    alignContent: "flex-start",
    display: "flex",
    height: "100%",
    paddingBottom: 0,
  },
  GNumber: {
    flex: 2.6,
    padding: 2,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    height: "100%",
    display:'flex',
    // flexWrap: 'wrap',
    alignContent: "stretch",
    flexDirection: 'row',
    
    
  },
  priceTotalRight: {
    fontSize: 11,
    textAlign: "right",
  },
  GQty: {
    flex: 1.4,
    fontSize:7,
    padding: 2,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    height: "100%",
    paddingBottom: 0,
  },
  GAmt: {
    flex: 3,
    padding: 2,
    fontSize:7,
    borderRightWidth: 1,
    borderRightColor: "#777",
    borderRightStyle: "solid",
    height: "100%",
    paddingBottom: 0,
  },
  GAmtL: {
    flex: 3,
    padding: 2,
    fontSize:7,
    height: "100%",
    paddingBottom: 0,
  },
  productGrid: {
    padding: 3,
  },
  GridFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderTopColor: "#ccc",
    borderTopStyle: "solid",
    borderTopWidth: 1,
  },
  GridFooter2: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  productNote: {
    padding: 5,
    flex: 9,
  },
  OrderGrandTotal: {
    padding: 3.3,
    flex: 1.99,
  },
  priceRight: {
    fontSize: 7,
    textAlign: "right",
  },
  priceCenter: {
    fontSize: 7,
    textAlign: "center",
  },
  priceRightItems: {
    fontSize: 7,
    textAlign: "right",
  },
  priceCenterItems: {
    fontSize: 7,
    textAlign: "center",
  },
  grandTotalHeading: {
    fontSize: 9,
    textAlign: "left",
  },
  smallText: {
    fontSize: 8,
    color: "#666",
    marginTop: 5,
  },
  footer: {
    marginVertical: 0,
    marginHorizontal: 5,
  },
  footerMainTextTop: {
    fontSize: 8,
    color: "#666",
    marginBottom: 20,
  },
  footerMainText: {
    fontSize: 8,
    color: "#333",
    textAlign: "center",
  },
});

const InvoicesReportsComp = ({ data, type = "Invoices" }) => {
  
  console.log('New',data);
    return (<>
    <Document
      fileName="test.pdf"
      creator="ZA Autos"
      author="Mohsin Ahmed"
      title={type}
      keywords=""
      subject={type}
    >
        <Page  size="A4" style={styles.page} wrap>
          <View style={styles.pagewrapper} fixed>
            <View style={styles.topSection}>
              <View style={styles.InfoWrapper}>
                <Text style={styles.MainHeading}>Invoices Report</Text>
                <Text style={styles.SubHeading}>From:{moment(data.data.period.StartDate).format('L')} To:{moment(data.data.period.EndDate).format('L')} </Text>
                <Text style={styles.SubHeading}>Company: {data.data.period.channel.channel}, STRN: {data.data.period.channel.stn}, NTN: {data.data.period.channel.ntn}</Text>
                <Text style={styles.SubHeading}></Text>
                <Text style={styles.SubHeading}></Text>
              </View>
            </View>
          </View> 
          <View style={styles.grid}>
          <View style={styles.GridHeader} fixed>
            <View style={styles.GDate}>
              <Text style={styles.gridHeading}>Date</Text>
            </View>
            <View style={styles.GNumber}>
              <Text style={styles.gridHeading}>Invoice</Text>
              </View>
              <View style={styles.GDate}>
              <Text style={styles.gridHeading}>Type</Text>
            </View>
            <View style={styles.GCompany}>
              <Text style={styles.gridHeading}>Company</Text>
            </View>
            <View style={styles.GQty}>
              <Text style={styles.priceCenter}>Qty</Text>
            </View>
            <View style={styles.GAmt}>
              <Text style={styles.priceCenter}>Gross Amt</Text>
            </View>
            <View style={styles.GAmt}>
              <Text style={styles.priceCenter}>
                GST
              </Text>
            </View>
            <View style={styles.GAmtL}>
              <Text style={styles.priceCenter}>Total Amt</Text>
            </View>
            </View>
            {data.data.records &&
           data.data.records.map((item, index) => {
             //   console.log(data.Data[0].id);
             let c = item.bill_to_company
             let cabbr = c.match(/[A-Z]/g).join('');
              return (
                <>
                  <View style={styles.GridProducts}>
                    <View style={styles.GDate}>
                      <Text style={styles.gridText}>
                        {moment(item.invoice_created_at).format("L")}
                      </Text>
                    </View>
                    <View style={styles.GNumber}>
                    <Text style={styles.gridText2}>
                      {item.invoice_number_type}
                    </Text>
                   
                    
                    </View>
                    <View style={styles.GDate}>
                    <Text style={styles.gridText3}>
                        {item.tax_type ==='Sales' ? 'GST' : 'SST'}
                    </Text>
                    </View>
                    <View style={styles.GCompany}>
                    <Text style={styles.gridText}>
                        {item.bill_to_company}
                        {/* ({cabbr}) */}
                    </Text>
                    <Text style={styles.smallText}>
                      {/* Description: {item.sales_description} */}
                    </Text>
                    </View>
                    <View style={styles.GQty}>
                      <Text style={styles.priceCenter}>
                      {FormattingNumber(item.total_items_qty)}
                    </Text>
                  </View>
                  <View style={styles.GAmt}>
                    <Text style={styles.priceRight}>
                      {FormattingNumber(item.subtotal)}
                    </Text>
                  </View>
                  <View style={styles.GAmt}>
                    <Text style={styles.priceRight}>
                      {FormattingNumber(item.gst_total)}
                    </Text>
                  </View>

                  <View style={styles.GAmtL}>
                    <Text style={styles.priceRight}>
                      {FormattingNumber(item.grand_total_gst)}
                    </Text>
                  </View>
                  </View>
                </>
            
              );
           })}
            <View style={styles.GridHeader} fixed>
            <View style={styles.GDate}>
              <Text style={styles.gridHeading}></Text>
            </View>
            <View style={styles.GNumber}>
              <Text style={styles.gridHeading}></Text>
              </View>
              <View style={styles.GDate}>
              <Text style={styles.gridHeading}></Text>
            </View>
            <View style={styles.GCompany}>
              <Text style={styles.gridHeading}></Text>
            </View>
            <View style={styles.GQty}>
              <Text style={styles.priceCenter}>{FormattingNumber(data.data.Calc.CountTotal)}</Text>
            </View>
            <View style={styles.GAmt}>
              <Text  style={styles.priceRight}> {FormattingNumber(data.data.Calc.subTotal)}</Text>
            </View>
            <View style={styles.GAmt}>
              <Text style={styles.priceRight}>
              {FormattingNumber(data.data.Calc.gstTotal)}
              </Text>
            </View>
            <View style={styles.GAmtL}>
                <Text style={styles.priceRight}> {FormattingNumber(data.data.Calc.GrandTotal)}</Text>
            </View>
            </View>
          </View>
          <View
          style={{
            paddingTop: 5,
            paddingBottom: 15,
            paddingHorizontal: 0,
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginTop: 30,
            bottom: 50,
          }}
          fixed
          >
            <View
            style={{
              paddingTop: 5,
              paddingBottom: 15,
              paddingHorizontal: 10,
              position: "absolute",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              marginTop: 0,
              bottom: -50,
              borderTopColor: "#777",
              borderTopStyle: "solid",
              borderTopWidth: 1,
            }}
          >
            <View style={styles.footer}>  
              <Text style={styles.footerMainText}>KICT, NTN: 0803538-5   STRN:1190999992355 </Text>
                {/* <Text style={styles.footerMainText}>KICT, NTN: 0803538-5   STRN:1190999992355 </Text>
                <Text style={styles.footerMainText}>KICT,  NTN: 0803538-5   STRN:1190999992355 </Text>
                <Text style={styles.footerMainText}>KICT,  NTN: 0803538-5   STRN:1190999992355 </Text> */}
              <Text style={styles.MainText}></Text>
            </View>
            <Text
              style={styles.footerMainText}
              render={({ pageNumber, totalPages }) => {
                return `Page No. ${pageNumber}/${totalPages}`;
              }}
            />
          </View>
        </View>
        </Page>
    </Document>
    </>)
}

export default InvoicesReportsComp;